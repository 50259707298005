.MC-month {
    width: 100%;
}

.MC-month thead {
    font-weight: bold;
}

.MC-month th {
    width: 14.285714%;
}

.MC-month td {
    height: 40px;
    text-align: center;
    border-radius: 5px;
    padding: 0;
    overflow: hidden;
}

.MC-month td button {
    border: none;
    background-color: transparent;
    width: 100%;
    height: 100%;
    cursor: pointer;
    color: inherit;
}