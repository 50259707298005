.FMform-struct-background {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    background-image: linear-gradient( dodgerblue, white);
}

.FMform-struct-background .FMform-page {
    background-color: rgba(255,255,255,0.9);
    box-sizing: border-box;
    border-radius: 10px;
    box-shadow: 10px 10px 15px rgba(0,0,0,0.3);
    padding: 25px;
    max-width: 500px;
    max-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
}

@keyframes LOADING_FORM_FINISHING {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0px);
    }
}

.FMform-finishing {
    animation: LOADING_FORM_FINISHING 1s;
}