.play-effect {
    transition: 0.5s;
}

.effect--invisible {
    opacity: 0;
}

.effect--small {
    transform: scale(0.3);
    opacity: 0;
}

.effect--rotate {
    transition-timing-function: ease-in;
    transform: scale(0.0) rotate(90deg);
    opacity: 0;
}

.effect--right {
    transform: translateX(20px);
    opacity: 0;
}

.effect--left {
    transform: translateX(-20px);
    opacity: 0;
}

.effect--top {
    transform: translateY(-20px);
    opacity: 0;
}

.effect--bottom {
    transform: translateY(20px);
    opacity: 0;
}

@keyframes ANIM-UNMOUNT {
    0% {
        transform: scale(1);
    }
    40% {
        transform: scale(1.05) translateY(-10px);
    }
    100% {
        transform: scale(1.05) translateY(30px);
        opacity: 0;
    }
}

.effect--unmount {
    animation: ANIM-UNMOUNT 0.5s;
    animation-fill-mode: both;
}

@keyframes ANIM-SHAKE {
    0% {
        transform: scale(1);
    }
    40% {
        transform: scale(0.9);
    }
    50% {
        transform: scale(0.9) translateY(10px);
        opacity: 0.85;
    }
    60% {
        transform: scale(0.9) translateY(-10px);
        opacity: 0.70;
    }
    50% {
        transform: scale(0.9) translateY(10px);
        opacity: 0.55;
    }
    70% {
        transform: scale(0.9) translateY(-10px);
        opacity: 0.4;
    }
    80% {
        transform: scale(0.9) translateY(10px);
        opacity: 0.25;
    }
    90% {
        transform: scale(0.9) translateY(-10px);
        opacity: 0.2;
    }
    100% {
        transform: scale(0.9) translateY(-20px);
        opacity: 0;
    }
}

.effect--shake {
    animation: ANIM-SHAKE 0.5s;
    animation-fill-mode: both;
}

.effect--doblate {
    transform: rotateY(90deg);
    opacity: 0;
}

.effect--dispel {
    transform: skewX(-10deg) scale(0.9);
    opacity: 0;
}