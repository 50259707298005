.MC-calendary-month {
    background-color: dodgerblue;
    color: white;
}

.MC-calendary-month :disabled {
    color: rgba(255,255,255,0.3);
}

.MC-calendary-month .MC-top {
    display: flex;
    width: 100%;
    justify-content: center;
}

.MC-calendary-month .MC-top-box {
    max-width: 220px;
    width: 100%;
    display: flex;
}

.MC-calendary-month .MC-top-text {
    display: inline-flex;
    max-width: 200px;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.MC-calendary-month .MC-top button {
    background-color: transparent;
    border: none;
    font-weight: bold;
    border-radius: 10px;
    padding: 10px;
    color: inherit;
    cursor: pointer;
}

.MC-calendary-month .MC-top button:hover {
    background-color: rgba(0,0,0,0.1);
}

.MC-calendary-month .MC-month {
    background-color: rgba(255,255,255,0.15);
    padding: 10px;
}

.MC-calendary-month .MC-month button:hover {
    background-color: rgba(255,255,255,0.1)
}

.MC-calendary-month .MC-month button:active, .MC-calendary-month .MC-month button.MC-selected {
    background-color: rgba(0,0,0,0.1);
}