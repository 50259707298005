.FMform-page.frame--stretch {
    max-width: 100% !important;
}

.FMform-page.frame--screen {
    max-width: 100% !important;
    height: 100% !important;
    border-radius: 0px !important;
}

@keyframes show-page {
    from {opacity: 0;}
    to {opacity: 1;}
}

.MC-cites {
    margin: -10px -25px;
}

.MC-calendary-month {
    border-radius: 8px;
}

.FMform-error {
    color: darkred;
    font-weight: bold;
}

@keyframes text-error {
    from {
        opacity: 0;
        transform: translateY(-50%);
    }
    to {
        opacity: 1;
        transform: translateY(0%);
    }
}

.FMform-text-error {
    animation: text-error 0.5s;
    color: darkred;
}

.form-effect {
    animation: 0.5s show-page;
}

.FMform-page {
    width: 100%;
    display: flex;
    align-items: stretch;
    flex-direction: column;
}

.FMform-page-title {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 2.5em;
    color: dodgerblue;
}

.FMform-page-ok {
    font-size: 40px !important;
}

.FMform-page-subtitle {
    font-family: sans-serif;
    font-size: 1em;
    padding: 5px 0px 10px;
    color: gray;
    border-bottom: 1px solid rgba(0,0,0,0.1);
}

.FMform-row-element {
    margin: 10px 0px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.FMform-group-element {
    display: flex;
    justify-content: space-between;
}

.FMform-group-element.horz {
    flex-direction: row;
}

.FMform-group-element.vert {
    flex-direction: column;
}

.FMform-group-element-item {
    display: flex;
    width: 100%;
}

.FMform-row-element.FMform-left {
    align-items: flex-start;
}

.FMform-row-element.FMform-center {
    align-items: center;
}

.FMform-row-element.FMform-right {
    align-items: flex-end;
}

.FMform-left {
    justify-content: flex-start;
}

.FMform-center {
    justify-content: center;
}

.FMform-right {
    justify-content: flex-end;
}

.FMform-stretch {
    display: flex;
    flex-direction: column;
}

.FMform-checkbox-container {
    width: auto;
    display: flex;
    flex-direction: column;
}


.FMform-element-phone-select {
    border: none;
    border-right: 2px solid rgba(0,0,0,0.2);
    padding: 3px 10px 3px 3px;
    width: 60px;
}
.FMform-element-phone-select:focus {
    outline: none;
}

.FMform-element-phone {
    height: auto;
    width: 100%;
}

.FMform-element-phone-textfield {
    height: 40px;
}

.FMform-element-phone-option {
    display: block;
    width: 500px;
}

.FMform-element-select-text {
    width: 100%;
}

@media screen and (max-width: 500px) {

    .FMform-page-title {
        font-size: calc(5vw + 10px);
    }

    .FMform-page-ok {
        font-size: 10vw !important;
    }
}