.MC-cites {
    --color: red;
    padding: 20px 0px;
}

.MC-cites-top {
    display: flex;
    align-items: center;
}

.MC-cites-result {
    padding-top: 15px;
    font-weight: bold;
    text-align: center;
    color: var(--color);
    animation: loading-cites 0.5s;
}

.MC-cites-top button {
    background-color: transparent;
    border: none;
    color: var(--color);
    font-weight: bold;
    border-radius: 20px;
    width: 30px;
    height: 30px;
}

.MC-cites-top button:hover {
    background-color: rgba(0,0,0,0.05);
    cursor: pointer;
}

.MC-cites-top button:active {
    background-color: rgba(0,0,0,0.1);
}

.MC-cites-title {
    text-align: center;
    font-weight: bold;
    font-size: 1.5em;
    background-color: var(--color);
    color: white;
    border-radius: 10px;
    padding: 20px;
    width: 100%;
}

.MC-cites-subtitle {
    font-size: 15px;
    opacity: 0.65;
}

.MC-taps {
    padding: 5px 25px;
}

.MC-taps button {
    background-color: transparent;
    font-weight: bold;
    border: none;
    color: var(--color);
    opacity: 0.5;
    cursor: pointer;
}

@keyframes loading-cites {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.MC-cites-body {
    animation: loading-cites 0.5s;
    padding: 0px 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.MC-cites-body .MC-container {
    display: inline-flex;
    justify-content: center;
    width: 33.333332%;
    min-width: 70px;
}

.MC-cites-body button {
    margin: 5px 0px;
    width: 80%;
    min-width: 65px;
    border: 1px solid currentColor;
    border-radius: 5px;
    background-color: transparent;
    padding: 5px;
    color: var(--color);
    opacity: 0.6;
    font-weight: bold;
}

.MC-cites-body button:hover {
    background-color: var(--color);
    color: white
}

.MC-taps button.MC-selected {
    opacity: 1;
}

.MC-cites-body button {
    cursor: pointer;
}

.MC-cites-body button.MC-selected {
    background-color: var(--color);
    color: white;
    opacity: 1;
}

.MC-cites-body button:active {
    background-color: var(--color);
    transform: scale(98%);
}